// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-team-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-news-events-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/templates/news-events.js" /* webpackChunkName: "component---src-templates-news-events-js" */),
  "component---src-pages-404-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-service-index-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/community-service/index.js" /* webpackChunkName: "component---src-pages-community-service-index-js" */),
  "component---src-pages-contact-index-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-events-index-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/news_events/index.js" /* webpackChunkName: "component---src-pages-news-events-index-js" */),
  "component---src-pages-team-index-js": () => import("/home/bkc/src/WPFD/wpfd-blog/src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */)
}

exports.data = () => import("/home/bkc/src/WPFD/wpfd-blog/.cache/data.json")

